import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["text", "listsPanel", "radioBtn"];

  showSelectedValue(e) {
    this.textTarget.innerHTML = e.target.innerHTML // show selected value
    this.radioBtnTargets[parseInt(e.target.dataset.index)].checked = true;
    this.listsPanelTarget.open = !this.listsPanelTarget.open; // toggle lists panel
  }
}
